.navbarContainer {
  background-color: #f2f3f4;
  height: 84px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-text-color);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.header {
  display: flex;
  align-items: center;
  color: #0f0f10;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.286;
  height: 22px;
}

.headerTitle {
  position: relative;
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.headerSlackBadge {
  //   position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: #fff;
  top: -6px;
  right: -24px;
  height: 20px;
  width: 20px;
}

.navbarData {
  display: flex;
  align-items: center;
}

.langContainer {
  display: flex;
  align-items: center;
  height: 18px;
  width: 86px;
  margin-right: 20px;
}

.langBtn {
  padding: 0px 10px;
  cursor: pointer;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  background-color: #f2f3f4;
  &:hover {
    background-color: #f2f3f4;
  }
}

.langPicked {
  font-weight: 700;
}

.langUnpicked {
  color: #bbbbbb;
}

.logoutButton {
  color: white;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 700;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #333333;
  border: none;
  cursor: pointer;
}
