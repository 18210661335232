@import "shared/loading/Loading.module.scss";
@import "components/navigations-section/navbar/NavBar.scss";
@import "components/navigations-section/sidebar/Sibebar";

@import "containers/teams/Users.scss";


* {
  list-style-type: none;
  outline: none;
}

a {
  color: black;
  text-decoration: none;
}


.content-container {
  height: 100%;
  flex-grow: 1;
  background-color: #f2f3f4;
  overflow-y: auto;
}