.user-wrapper {
  padding: 30px 16px 16px 16px;
}

.user-head-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.user-title {
  margin: 0 0 20px;
}

.user-row:hover{
  background-color: rgb(241, 240, 240);
  transition: .2s;
}
.btn-more {
  display: flex !important;
  margin: auto !important;
}
#avatar {
  margin-right: 3px;
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 16px;
}
.full-name{
  display: flex;
  align-items: center;
}